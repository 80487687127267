<template>
    <Carousel />

    <!--
    <div class="col-12">
        <Carousel />
    </div>
-->
</template>

<script>
// @ is an alias to /src
import Carousel from '@/components/bootstrapDashboard/Carousel.vue'

export default {
    name: 'CarouselView',
    components: {
        Carousel
    }
}
</script>

<style>
    @import '../../assets/css/carousel.css';
</style>
